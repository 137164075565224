@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

/*
------------------------------------------------------------
Project Name : FasTab;
------------------------------------------------------------
*/

/* Reset 
--------------------------------------------------------------------------------------------------------*/

/* 
http://meyerweb.com/eric/tools/css/reset/
v2.0 | 20110126
License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* General 
--------------------------------------------------------------------------------------------------------*/
*,
*:after,
*:before {
  outline: none !important;
  box-sizing: border-box;
}
a {
  transition: all 0.3s;
}
html,
body {
  min-width: 320px;
  min-height: 100vh;
  background-color: #f3f5f8;
}
body {
  font-size: 16px;
  line-height: 22px;
  font-family: 'Poppins', sans-serif;
  -ms-overflow-style: scrollbar;
  -webkit-font-smoothing: subpixel-antialiased;
}
.faq-link {
  text-decoration: none !important;
}
.wrapper {
  min-height: 100vh;
  padding-top: 80px;
  padding-bottom: 30px;
  background-color: #f3f5f8;
  background-image: url(./components/Designer/assets/images/landing-bg.svg);
  background-repeat: repeat;
}
.formm-wrapper {
  min-height: 100vh;
  background: #f3f5f8;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}
.from-mobile-logo {
  display: block;
  margin: 0 auto 20px;
}
.fastab-guest-logo {
  height: 40px;
}
.wrapper.with-footer {
  padding-bottom: 146px;
}
a {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
a:hover {
  text-decoration: none;
}
h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
h6 span {
  display: block;
}
h1 {
  font-size: 32px;
  line-height: 42px;
}
h2 {
  font-size: 30px;
  line-height: 45px;
  letter-spacing: 0.5px;
  padding-bottom: 6px;
}
h4 {
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.5px;
}
h5 {
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.5px;
}
h6 {
  font-size: 14px;
  line-height: 19px;
}
input[type='text'],
input[type='email'],
input[type='password'],
textarea {
  -webkit-appearance: none;
  appearance: none;
}
::-webkit-input-placeholder {
  color: #000;
}
::-moz-placeholder {
  color: #000;
}
:-ms-input-placeholder {
  color: #000;
}
:-moz-placeholder {
  color: #000;
}
::-moz-focus-inner {
  border: 0;
}
select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
option:not(:checked) {
  color: black;
}
.pb-15 {
  padding-bottom: 15px !important;
}
.p-0 {
  padding: 0 !important;
}
.MuiContainer-root.MuiContainer-maxWidthMd {
  padding-left: 16px;
  padding-right: 16px;
}
.page-title {
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.5px;
  color: #737373;
  font-weight: 600;
  padding-bottom: 10px;
  text-transform: uppercase;
  position: relative;
}
.help-tip {
  position: absolute;
  display: block;
  background: none;
  border: none;
  z-index: 1;
  height: 22px;
  width: 22px;
  top: 50%;
  transform: translateY(-50%);
  right: -30px;
  padding: 0;
}
.sub-title {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  font-weight: 500;
  color: #737373;
  padding-top: 10px;
  padding-bottom: 10px;
}
.MuiGrid-root.MuiGrid-spacing-xs-3 {
  margin: 0 !important;
  width: 100% !important;
}
.MuiGrid-root.MuiGrid-spacing-xs-3 > .MuiGrid-item {
  padding: 0 !important;
}
.primary-link {
  color: #008cff !important;
  text-decoration: none !important;
  font-weight: 600;
}
.primary-link:hover {
  text-decoration: underline !important;
  color: #008cff !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}

/* error-message
--------------------------------------------------------------------------------------------------------*/
.error-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.error-message h1 {
  background: -webkit-linear-gradient(0deg, #3d8af7 34%, #bbdcf3 100%);
  background: -o-linear-gradient(0deg, #3d8af7 34%, #bbdcf3 100%);
  background: linear-gradient(0deg, #3d8af7 34%, #bbdcf3 100%);
  background-clip: text;
  -webkit-background-clip: text;
  display: block;
  -webkit-text-fill-color: transparent;
  font-size: 190px;
  line-height: 190px;
  font-weight: 600;
  text-shadow: 0px 92px 252px #dddddd;
  text-align: center;
  margin-bottom: 28px;
}
.browserIE .error-message h1 {
  background: transparent;
  -webkit-text-fill-color: #3d8af7;
  color: #3d8af7;
}
.error-message h2 {
  font-size: 42px;
  line-height: 52px;
  color: #737373;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}
.error-message p {
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
  color: #a6a6a7;
  text-align: center;
  max-width: 762px;
  margin: 0 auto 62px;
}
.error-message .MuiButton-root {
  min-width: 170px;
}

.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* buttons
--------------------------------------------------------------------------------------------------------*/

.MuiButton-contained.MuiButton-root.account-button {
  width: 400px !important;
  max-width: 400px !important;
  margin: 0 auto 35px auto !important;
}
.MuiButton-root.MuiButtonBase-root.change-split {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  min-width: 160px;
}
.MuiButton-root.MuiButtonBase-root.change-split img {
  height: 12px;
  width: 12px;
  margin-right: 8px;
}
.MuiButton-root.MuiButtonBase-root {
  border-radius: 50px;
  padding: 6px 12px;
  display: flex;
  font-weight: 600;
  align-items: center;
  font-size: 18px;
  line-height: 27px;
  justify-content: center;
  text-transform: unset !important;
  box-shadow: none;
  overflow: hidden;
  position: relative;
  min-width: auto;
}
.MuiButtonBase-root.MuiButton-root span:not(.MuiTouchRipple-root) {
  z-index: 1;
  position: relative;
}
.MuiButton-root.MuiButtonBase-root > img {
  display: block;
  position: relative;
  z-index: 1;
  height: 24px;
  width: 24px;
  margin-right: 5px;
}
.MuiButton-root.MuiButtonBase-root.MuiButton-sizeSmall {
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  min-height: 30px;
  min-width: 140px;
  padding: 5px 14px;
}
.MuiButton-outlinedPrimary.MuiButton-root {
  color: #008cff;
  border-color: #008cff;
}
.MuiButton-outlinedSecondary.MuiButton-root {
  color: #737373;
  border-color: #d8d8d8;
}
.MuiButton-outlinedSecondary.MuiButton-root:hover {
  border-color: #d8d8d8;
}
.MuiButton-contained.MuiButton-root {
  background: linear-gradient(90deg, #3d8af7 0%, #5fb6f6 100%);
  padding: 10px 20px 11px;
}

.MuiButton-contained.MuiButton-root.Mui-disabled {
  background: #c7c7c7;
  color: #fff;
}
.MuiButton-outlined.MuiButton-root {
  padding: 5px 20px 5px;
}
.MuiButton-contained.MuiButton-root small {
  padding: 0 10px;
  font-weight: 400;
  opacity: 0.5;
}
.MuiButton-contained.MuiButton-root::after,
.MuiButton-outlinedPrimary.MuiButton-root::after,
.MuiButton-outlinedSecondary.MuiButton-root::after {
  content: '';
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  transform: scale(0, 1);
  border-radius: 50px;
}
.MuiButton-contained.MuiButton-root:hover::after,
.MuiButton-contained.MuiButton-root:focus::after,
.MuiButton-outlinedPrimary.MuiButton-root:hover::after,
.MuiButton-outlinedPrimary.MuiButton-root:focus::after,
.MuiButton-outlinedSecondary.MuiButton-root:hover::after,
.MuiButton-outlinedSecondary.MuiButton-root:focus::after {
  transform: scale(1, 1);
}
.MuiButton-outlinedPrimary.MuiButton-root::after {
  background: #e1f0fc;
}
.MuiButton-outlinedSecondary.MuiButton-root::after {
  background: #f3f3f3;
}
.btn-list {
  display: flex;
}
.btn-list .MuiButton-root:not(:last-child) {
  margin-right: 10px;
}
.MuiButton-root.MuiButton-containedSecondary {
  background: #fff;
  color: #008cff;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 18px;
  transition: none;
  box-shadow: 0 2px 12px rgba(28, 58, 81, 0.11);
}
.MuiButton-root.lg-font {
  font-size: 18px;
  line-height: 20px;
}
.MuiButton-root.MuiButton-containedSecondary img {
  margin-right: 8px;
}
.MuiButton-root.MuiButton-containedSecondary img.active,
.MuiButton-root.MuiButton-containedSecondary:hover img.default {
  display: none;
}
.MuiButton-root.MuiButton-containedSecondary:hover {
  background: linear-gradient(90deg, #008cff 0, #5fb6f6 100%);
  color: #fff;
}
.MuiButton-root.MuiButton-containedSecondary:hover img.active {
  display: block;
}
.MuiButton-root.MuiButton-containedSecondary::after {
  display: none;
}
/* check list
--------------------------------------------------------------------------------------------------------*/
.check-list {
  background: #e2f2ff;
  min-height: auto;
  min-height: 40px;
  padding-right: 10px;
  padding-left: 10px;
  display: flex;
  align-items: center;
}

.check-list .MuiCheckbox-root {
  margin-left: -9px;
}

.check-list span:not(.MuiCheckbox-root) {
  margin-left: auto;
  font-size: 12px;
  line-height: 18px;
  color: #737373;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 102px;
  text-align: right;
}
.check-list.paid span {
  margin-left: 0;
}

.check-list strong {
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  color: #737373;
  padding-left: 6px;
}

.check-list label {
  font-size: 16px;
  line-height: 18px;
  color: #737373;
  font-weight: 600;
  padding-right: 6px;
  margin-left: -3px;
}

.check-list p {
  font-size: 16px;
  line-height: 18px;
  color: #737373;
  font-weight: 600;
  padding-right: 6px;
  margin-left: -3px;
}

.check-ul {
  padding-top: 14px !important;
}

.check-list.paid img {
  display: block;
  margin: 9px 9px 9px 1px;
}

.check-list.paid {
  background: #edeff0;
}

.check-list-img {
  display: block;
  margin: 9px 9px 9px 1px;
}

.table-details-collapse.item-selection ul.check-list-items {
  padding: 0;
}
.table-details-collapse.item-selection ul.check-list-items li {
  padding-left: 32px;
  margin: 4px 0;
}
/* form-group
--------------------------------------------------------------------------------------------------------*/
.form-group .MuiFormControl-root .MuiInputLabel-root {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 14px;
  color: #a6a6a7;
  transform: translate(0, 14px) scale(1);
}
.form-group .error-text {
  font-weight: 400;
  font-size: 0.75rem;
  display: block;
  line-height: 1.66;
  text-align: left;
  margin: 3px 0px 0px;
  color: rgb(211, 47, 47);
}
.form-group .form-control {
  border: 1px solid #d8d8d8;
  font-size: 14px;
  outline: none;
  height: 48px;
  transition: all 0.3s ease;
  font-family: 'Poppins', sans-serif;
  padding: 10px;
  color: #000;
  width: 100%;
}
.form-group .form-control:focus {
  border-color: #008cff;
}
.form-group .form-control .InputElement {
  font-family: 'Poppins', sans-serif;
}
.invalid-feedback {
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  text-align: left;
  margin: 3px 0px 0px;
  color: rgb(211, 47, 47);
}

.payment-unsuccessful {
  margin-bottom: 15px;
  font-weight: 600;
}
.form-group .ElementsApp .InputElement.is-invalid {
  color: rgb(211, 47, 47) !important;
}
.form-group.card-input > img {
  position: absolute;
  right: 10px;
  bottom: 19px;
}
.form-group.card-input .form-control {
  padding-right: 80px;
}
.form-group .form-control.payment-input {
  padding-top: 15px;
}
.form-group .form-control.payment-input.is-invalid {
  border-color: #d32f2f;
}
.form-group .form-lable {
  display: block;
  font-size: 12px;
  line-height: 12px;
  padding-bottom: 8px;
  color: #a6a6a7;
}
.form-group .form-control::placeholder {
  font-size: 14px;
  line-height: 18px;
  color: #737373;
  font-family: 'Poppins', sans-serif;
}
.form-group {
  margin-bottom: 20px;
  width: 100%;
  position: relative;
}
.card-payment .form-group {
  margin-bottom: 14px;
}
.card-payment .MuiButton-contained {
  margin: 20px auto 10px;
  max-width: 240px;
}
.card-error {
  font-size: 12px;
  line-height: 14px;
  color: #d32f2f;
}
.form-group .MuiFormControl-root {
  width: 100%;
}
.form-group .MuiFormControl-root .MuiInputLabel-root.Mui-focused {
  color: #3d8af7;
}
.form-group .MuiFormControl-root .MuiInputLabel-root.Mui-focused,
.form-group .MuiFormControl-root .MuiInputLabel-root.MuiInputLabel-shrink {
  transform: translate(0, -1.5px) scale(0.75);
}
.form-group .MuiFormControl-root .MuiInput-root {
  margin-top: 5px;
}
.form-group .MuiFormControl-root .MuiInput-input {
  color: #5f5f5f;
  font-size: 16px;
  padding: 8px 0 5px;
}
.form-group .MuiFormControl-root .MuiInput-root:hover:not(.Mui-disabled):before,
.form-group .MuiFormControl-root .MuiInput-root:before {
  border-color: #d8d8d8;
}
.form-group .MuiFormControl-root .MuiInput-root.Mui-focused:after {
  border-bottom: 1px solid #3d8af7;
}
.form-group .MuiInputBase-root:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid #d8d8d8;
}

/* menu item list
--------------------------------------------------------------------------------------------------------*/
.menu-item-list {
  padding: 0 20px 10px;
}
.menu-item-list li {
  display: flex;
  margin-bottom: 16px;
}
.menu-item-list li span {
  font-size: 14px;
  line-height: 20px;
}
.menu-item-list li .item-details {
  color: #a6a6a7;
  padding-right: 10px;
}
.menu-item-list li .item-details strong {
  display: block;
  color: #5e6266;
  font-weight: 600;
  padding-bottom: 2px;
}
.menu-item-list li .item-price {
  color: #a6a6a7;
  font-weight: 600;
  margin-left: auto;
}

/* header
--------------------------------------------------------------------------------------------------------*/
.landing-page .table-number {
  font-size: 16px;
  line-height: 24px;
  padding-top: 4px;
  text-align: center;
  font-weight: 600;
  color: #737373;
  padding-bottom: 44px;
}
header {
  background: #fff;
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.1));
}
.header,
.header.MuiContainer-root {
  display: flex;
  padding: 7px 5px 8px 5px !important;
  justify-content: space-between;
  align-items: center;
}
.header .logo,
.header .logo img {
  display: block;
}
.header .resturant-info {
  max-width: calc(100% - 110px);
}
.header .resturant-info p {
  font-size: 16px;
  text-align: center;
  line-height: 24px;
  font-weight: 600;
  color: #737373;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.header .resturant-info small {
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #737373;
}
.user-menu-btn.MuiButton-root {
  height: 35px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 35px !important;
  border-radius: 50%;
  background: #e1f0fc;
  overflow: visible;
}

.user-menu-btn.MuiButton-root small {
  padding: 0;
  opacity: 1;
  font-weight: 600;
  color: #008cff;
  font-size: 16px;
}
.user-menu-btn.MuiButton-root:not(.badge)::after {
  display: none;
}
.user-menu-btn.MuiButton-root:hover,
.user-menu-btn.MuiButton-root:focus {
  background: #e1f0fc;
}
.user-menu-btn.MuiButton-root img {
  height: 24px;
  margin: 0;
  width: 24px;
}
.badge.MuiButton-root {
  position: relative;
}
.badge.MuiButton-root::after {
  content: '';
  position: absolute;
  top: 0;
  transform: none;
  left: 0;
  height: 8px;
  width: 8px;
  display: block;
  border-radius: 50%;
}
.badge.badge-warning.MuiButton-root::after {
  background: #fdb954;
}

/* main-tab
--------------------------------------------------------------------------------------------------------*/
.MuiButtonGroup-root.main-tab .MuiButton-root {
  background: #fff;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  color: #3d8af7;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 6px;
  min-width: 130px;
  text-transform: uppercase;
}
.MuiButtonGroup-root.main-tab {
  position: relative;
  box-shadow: 0 2px 12px rgba(28, 58, 81, 0.11);
  width: 260px;
  display: flex;
  margin: 0 auto 12px;
  border-radius: 44px;
}
.MuiButtonGroup-root.main-tab .MuiButton-root.active {
  background: linear-gradient(90deg, #008cff 0, #5fb6f6 100%);
  color: #fff;
}
.MuiButtonGroup-root.main-tab .MuiButton-root.active img.active {
  display: block;
}
.MuiButtonGroup-root.main-tab .MuiButton-root.active img.default,
.MuiButtonGroup-root.main-tab .MuiButton-root img.active {
  display: none;
}
.MuiButtonGroup-root.main-tab .MuiButton-root img {
  display: block;
  height: 24px;
  width: 24px;
  margin-right: 5px;
}
.MuiButtonGroup-root.main-tab .MuiButton-root:not(:last-child) {
  border-color: #d8d8d8;
}
.MuiButtonGroup-root.main-tab.active .MuiButton-root:not(:last-child) {
  border: none;
}
.MuiButtonGroup-root.main-tab .MuiButton-root::after {
  display: none;
}
.MuiButtonGroup-root.main-tab .MuiButton-root:first-child {
  border-top-left-radius: 44px;
  border-bottom-left-radius: 44px;
}
.MuiButtonGroup-root.main-tab .MuiButton-root:last-child {
  border-top-right-radius: 44px;
  border-bottom-right-radius: 44px;
}
.MuiButtonGroup-root.main-tab .MuiButton-root:hover img.default {
  display: block;
}
.MuiButtonGroup-root.main-tab .MuiButton-root.active:hover img.default {
  display: none;
}

.MuiButtonGroup-root.main-tab .MuiButton-root:hover img.default {
  display: block;
}

.MuiButtonGroup-root.main-tab .MuiButton-root.active:hover img.default {
  display: none;
}

/* animation-wrapper
--------------------------------------------------------------------------------------------------------*/
.animation-wrapper {
  position: relative;
}
.animation-wrapper.payment-successful-animation {
  margin-top: -20px;
}
.animation-wrapper > div:not(.logo-wrapper) {
  background: #f3f5f8;
  position: relative;
}
.logo-wrapper {
  height: 200px;
  border-radius: 15px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}
.logo-wrapper img {
  display: block;
  max-width: 100%;
  max-height: 200px;
}
.animation-wrapper .logo-wrapper {
  position: absolute;
  opacity: 0;
  transition: all 0.6s ease;
  left: 0;
  top: 10px;
  right: 0;
}
.animation-wrapper .logo-wrapper.show {
  opacity: 1;
}
.animation-wrapper.payment-successful-animation .logo-wrapper {
  top: 52px;
  height: 200px;
}
.animation-wrapper.payment-successful-animation .logo-wrapper img {
  max-height: 200px;
}
.landing-page .animation-wrapper {
  margin-bottom: 16px;
}
.landing-page h1,
h1.light-text {
  font-weight: 200;
  padding-bottom: 14px;
  letter-spacing: -0.48px;
  text-align: center;
  color: #737373;
}
.styled-text {
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.48px;
  font-weight: 500;
  color: #fff;
  padding: 4px 45px 8px 75px;
  width: 290px;
  margin: 0 auto;
  text-align: center;
  background-image: url(./components/Designer/assets/images/text-bg.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}

/* Home
--------------------------------------------------------------------------------------------------------*/
.wrapper.home {
  padding-top: 110px;
}
.wrapper.home h1 {
  text-align: center;
}
.home h1:first-child {
  padding-bottom: 5px;
}

.home .MuiContainer-root > span {
  font-size: 14px;
  text-align: center;
  display: block;
  line-height: 19px;
  color: #5f5f5f;
}
.home ul li {
  display: flex;
}
.home ul li span {
  display: block;
  width: 20px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  text-align: center;
  color: #737373;
  margin-right: 10px;
}

.home .scan-text {
  color: #5f5f5f;
  display: block;
  font-weight: 400;
  line-height: 18px;
  padding-right: 10px;
}

.home ul li small {
  font-size: 12px;
  line-height: 18px;
  color: #5f5f5f;
  font-weight: 600;
  display: block;
  max-width: 250px;
  padding-right: 10px;
}
.home ul li img {
  display: block;
  height: 24px;
  width: 24px;
  margin-left: auto;
}
.home ul li {
  margin-bottom: 30px;
}
.home ul {
  max-width: 332px;
  padding: 60px 0 30px;
  margin: 0 auto;
}
.home .MuiButton-root {
  min-width: 240px;
  margin: 0 auto 100px;
}

/* primary-menu
--------------------------------------------------------------------------------------------------------*/
.pdf-pages canvas {
  width: 100% !important;
  height: auto !important;
}

.primary-menu .MuiMenu-paper {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}
.primary-popover .MuiPopover-paper {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}
.user-registration {
  display: flex;
  padding: 16px 14px;
  flex-direction: column;
  align-items: center;
}
.user-registration > p {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #737373;
  font-weight: 600;
  padding-bottom: 6px;
}
.user-registration > span {
  font-size: 12px;
  line-height: 18px;
  display: block;
  color: #a6a6a7;
  text-align: center;
  padding-bottom: 6px;
  max-width: 224px;
}
.user-registration > span {
  padding-bottom: 0;
}
.user-registration > small {
  font-size: 12px;
  line-height: 18px;
  display: block;
  color: #737373;
  text-align: center;
  padding-bottom: 1px;
  font-weight: 500;
  max-width: 224px;
}
.user-registration .MuiButton-root {
  margin: 0 auto;
}
.user-registration .login-btn {
  margin-bottom: 26px;
  margin-top: 6px;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.primary-menu .MuiMenuItem-root {
  padding: 0;
  min-height: auto;
}
.primary-menu .MuiMenuItem-root a {
  color: #737373;
  min-width: 152px;
  padding: 1px 16px;
  display: block;
  font-size: 12px;
  line-height: 28px;
}

/* menu-wrapper
--------------------------------------------------------------------------------------------------------*/
.card {
  background: #ffffff;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}
.card.bordered {
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  padding: 15px 16px;
  margin-bottom: 10px;
  box-shadow: none;
}
.card.menu-wrapper {
  box-shadow: none;
}
.menu-wrapper {
  margin-top: 22px;
}

/* primary-tabs
--------------------------------------------------------------------------------------------------------*/
.primary-tabs.MuiTabs-root {
  margin: 0 15px;
  min-height: auto;
  border-bottom: 1px solid #dde3ec;
}
.primary-tabs .MuiTab-root {
  font-size: 16px;
  padding: 16px 15px;
  min-height: auto;
  line-height: 20px;
  font-weight: 500;
  color: #a6a6a7;
  opacity: 1;
  letter-spacing: 0.48px;
}

.react-pdf__Page__textContent {
  visibility: hidden;
  opacity: 0;
}

.primary-tabs .MuiTab-root.Mui-selected {
  color: #3d8af7;
}
.tab-content img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}
.primary-tabs .MuiTabs-indicator {
  background-color: #3d8af7;
}

/* secondary-tabs
--------------------------------------------------------------------------------------------------------*/
.secondary-tabs.MuiToggleButtonGroup-root {
  display: flex;
  padding: 15px 0;
  margin: 0 15px;
  overflow-x: auto;
  scrollbar-width: none;
}
.secondary-tabs.MuiToggleButtonGroup-root::-webkit-scrollbar {
  display: none;
}
.secondary-tabs.MuiToggleButtonGroup-root .MuiButtonBase-root {
  padding: 7px 10px;
  border-radius: 50px !important;
  border: 1px solid transparent;
  text-transform: unset;
  font-weight: 400;
  letter-spacing: 0.48px;
  transition: all 0.3s ease;
  color: #8a9fb0;
  font-size: 13px;
  line-height: 14px;
  flex-shrink: 0;
  -webkit-text-size-adjust: none;
}
.secondary-tabs.MuiToggleButtonGroup-root .MuiButtonBase-root:first-child {
  margin-left: 0 !important;
}
.secondary-tabs.MuiToggleButtonGroup-root .MuiButtonBase-root:last-child {
  margin-right: 0 !important;
}
.secondary-tabs.MuiToggleButtonGroup-root .MuiButtonBase-root.Mui-selected {
  border-color: #bbd5eb;
  background: transparent;
  padding-left: 14px;
  padding-right: 14px;
  margin: 0 10px;
}

/* table-details-collapse
--------------------------------------------------------------------------------------------------------*/
.table-details-header {
  display: flex;
  padding: 12px 15px 16px 20px;
  align-items: center;
}
.table-details-header img {
  height: 40px;
}
.table-details-header p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #737373;
}
.table-details-header span {
  margin-left: auto;
  font-size: 12px;
  line-height: 18px;
  color: #737373;
}
.table-details-header small {
  padding: 0 7px;
}
.table-details-collapse.MuiAccordion-root {
  box-shadow: none;
  margin: 0 0 2px !important;
}
.table-details-collapse.MuiAccordion-root::before {
  display: none;
}
.table-details-collapse .MuiAccordionSummary-root {
  background: #e2f2ff;
  min-height: auto;
  min-height: 40px;
  padding-right: 15px;
  padding-left: 15px;
}
.table-details-collapse.paid .MuiAccordionSummary-root {
  background: #edeff0;
}
.table-details-collapse .MuiAccordionSummary-root.Mui-expanded {
  min-height: 40px;
}
.table-details-collapse .MuiAccordionSummary-root .MuiAccordionSummary-content {
  display: flex;
  align-items: center;
  margin: 0;
}
.table-details-collapse .MuiAccordionSummary-content .MuiCheckbox-root {
  margin-left: -9px;
}
.table-details-collapse .MuiAccordionSummary-content > img {
  display: block;
  margin: 9px 9px 9px 1px;
}
.split-by-seat
  .table-details-collapse
  .MuiAccordionSummary-content
  p:not(.pay-status) {
  margin-left: -3px;
}
.table-details-collapse .MuiAccordionSummary-content p:not(.pay-status) {
  margin-left: 0;
  font-size: 16px;
  line-height: 18px;
  color: #737373;
  font-weight: 600;
  padding-right: 10px;
}
.pay-status.pay-page {
  margin-left: auto !important;
  margin-right: 10px !important;
}
.amount-span {
  margin-left: unset !important;
}
.table-details-collapse p.pay-status {
  font-size: 13px;
  line-height: 18px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  padding: 3px 13px;
  margin-left: 6px;
  border-radius: 50px;
  background: #a6a6a7;
}
.table-details-collapse.item-selection p.pay-status {
  margin-left: auto;
  margin-right: 10px;
}
.table-details-collapse .MuiAccordionSummary-content span {
  margin-left: auto;
  font-size: 12px;
  line-height: 18px;
  color: #737373;
  display: flex;
  align-items: center;
}
.table-details-collapse .MuiAccordionSummary-content span strong {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #737373;
  padding-left: 6px;
}
.table-details-collapse .MuiAccordionDetails-root {
  padding: 14px 15px 14px 30px;
}
.table-details-collapse ul li {
  display: flex;
  align-items: center;
}
.table-details-collapse ul li:not(:last-child) {
  margin-bottom: 10px;
}
.table-details-collapse ul li > span:not(.MuiCheckbox-root) {
  font-size: 12px;
  position: relative;
  line-height: 16px;
  color: #737373;
}
.table-details-collapse > ul {
  padding: 0 15px 14px 25px;
}
.table-details-collapse.item-selection > ul {
  padding-left: 18px;
}
.table-details-collapse ul li > span:not(.MuiCheckbox-root):first-child {
  padding-left: 13px;
}
.table-details-collapse.item-selection
  ul
  li
  > span:not(.MuiCheckbox-root):first-child {
  padding-left: 0;
}
.table-details-collapse ul li > span:not(.MuiCheckbox-root):last-child {
  margin-left: auto;
}
.table-details-collapse
  ul
  li.paid.check-not-paid
  > span:not(.MuiCheckbox-root):last-child {
  margin-left: 0;
}
.table-details-collapse
  ul
  li
  > span:not(.MuiCheckbox-root):first-child::before {
  content: '';
  position: absolute;
  display: block;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: #a6a6a7;
  top: 5px;
  left: 0;
}
.table-details-collapse.item-selection
  ul
  li
  > span:not(.MuiCheckbox-root):first-child::before {
  display: none;
}
.table-details-collapse.item-selection ul li {
  padding: 4px 10px 4px 2px;
  margin-bottom: 4px;
  border-radius: 4px;
}
.table-details-collapse.item-selection .MuiCheckbox-root {
  padding: 5px;
  margin-right: 4px;
}
.table-details-collapse.item-selection .MuiCheckbox-root img {
  height: 20px;
}
.table-details-collapse.item-selection ul li.selected {
  background: #e2f2ff;
}
.table-details-collapse.item-selection ul li.paid {
  background: #edeff0;
}
.table-details-collapse.item-selection ul li.paid img {
  margin: 5px 9px 5px 5px;
}
.table-details-body > ul {
  padding: 0 15px 20px;
}
.table-details-body > ul .separator {
  height: 1px;
  background-image: url('./components/Designer/assets/images/ic-dash-border.svg');
  background-position: center;
  padding: 4px 0;
  background-repeat: repeat-x;
}
.table-details-body
  .table-details-collapse
  > ul
  li
  > span:not(.MuiCheckbox-root) {
  /* font-size: 14px; */
  line-height: 19px;
  min-width: 55px;
  color: #737373;
  text-align: right;
}
.table-details-body > ul li small {
  font-size: 10px;
  line-height: 19px;
  padding-left: 4px;
}
.table-details-body > ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 0 5px 10px;
}
.table-details-body > ul li.grand-total span {
  color: #1d1d1d;
  font-weight: 600;
}
.table-details-body > ul li.grand-total p {
  color: #1d1d1d;
  font-size: 18px;
  line-height: 19px;
  font-weight: 600;
}
.empty-tab {
  padding: 24px 16px 30px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.empty-tab > img {
  margin-bottom: 25px;
  max-width: 264px;
}
.empty-tab p {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: #737373;
  text-align: center;
  padding-bottom: 15px;
}
.empty-tab span {
  font-size: 12px;
  line-height: 18px;
  display: block;
  color: #737373;
  text-align: center;
}
.view-menu-btn.MuiButton-root {
  position: fixed;
  bottom: 30px;
  min-width: 240px;
  left: 50%;
  transform: translatex(-50%);
}
.category-accordion {
  margin: 10px 10px;
}

.category-accordion .MuiPaper-root {
  margin: 0;
  box-shadow: none;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
}
.category-accordion .MuiPaper-root:before {
  display: none;
}
.category-accordion .MuiPaper-root + .MuiPaper-root {
  margin-top: 10px;
}

.category-accordion .MuiAccordionSummary-root {
  min-height: 45px;
}

.category-accordion .MuiAccordionSummary-root .MuiAccordionSummary-content {
  margin: 7px 0;
  color: #737373;
  font-weight: 600;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
}
.menu-accordion-item-count {
  display: flex;
  align-items: center;
  font-size: 12px !important;
  font-weight: 450;
}
.menu-subcategory-accordion-name {
  max-width: 75%;
}
/* tip-radios
--------------------------------------------------------------------------------------------------------*/
.tips-wrapper > span {
  display: flex;
  padding-bottom: 20px;
  font-size: 14px;
  color: #737373;
  line-height: 21px;
  letter-spacing: 0.5px;
  align-items: center;
  justify-content: space-between;
}
.tip-radios > span strong,
.tips-wrapper > span strong {
  font-weight: 600;
}
.tip-radios {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}
.tip-radios li {
  flex-grow: 1;
  position: relative;
  margin: 0 5px;
  flex-shrink: 1;
  flex-basis: 0;
  position: relative;
}

.tip-radios li input[type='radio'] {
  position: absolute;
  height: 0;
  width: 0;
  top: 0;
  left: 0;
  opacity: 0;
}
.tip-radios li > label {
  font-size: 18px;
  padding: 10px 12px;
  cursor: pointer;
  border: 1px solid #d8d8d8;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 4px;
  line-height: 24px;
  align-items: center;
  color: #333;
  font-weight: 600;
  letter-spacing: 0.5px;
}
.tip-radios li > label span {
  font-size: 11px;
  line-height: 16px;
  color: #a6a6a7;
}
.tip-radios li label strong {
  display: block;
  margin-top: auto;
  font-size: 12px;
  line-height: 18px;
  color: #a6a6a7;
  font-weight: 500;
}
.tip-radios li input[type='radio']:checked ~ label,
.tip-radios li input[type='radio']:focus ~ label {
  border-color: #008cff;
  color: #008cff;
}
.tip-radios li input[type='radio']:checked ~ label strong,
.tip-radios li input[type='radio']:focus ~ label strong {
  color: #008cff;
}
.tip-radios li.custom-tip {
  flex-basis: 100%;
  margin-top: 10px;
  min-height: 50px;
}
.tip-radios li.custom-tip .custom-percent {
  position: absolute;
  top: 2px;
  right: 10px;
  font-size: 12px;
  font-weight: 600;
  color: #333;
  display: block;
}
.tip-radios li.custom-tip > label {
  padding: 5px 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}
.tip-radios li.custom-tip::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  border: 1px solid transparent;
}
.tip-radios li.custom-tip.active::after {
  border-color: #008cff;
}
.tip-radios li.custom-tip .form-group {
  margin: 0;
  display: block;
  padding: 7px 7px 4px;
  position: relative;
  z-index: 1;
}
.tip-radios li.custom-tip .MuiInputAdornment-root p {
  font-size: 14px;
  line-height: 14px;
  margin-bottom: -3px;
}
.tip-radios li.custom-tip .MuiInput-input {
  margin-left: -5px;
  padding: 8px 0 5px 5px;
}
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
input[type='number'] {
  -moz-appearance: textfield;
}

/* total-due
--------------------------------------------------------------------------------------------------------*/
.total-due ul li {
  display: flex;
  padding: 5px 0 6px;
  align-items: center;
  justify-content: space-between;
}
.total-due ul li span {
  font-size: 14px;
  line-height: 19px;
  color: #737373;
}
.total-due ul li.grand-total strong {
  font-size: 16px;
  line-height: 19px;
  color: #212121;
  font-weight: 600;
}

/* payment-options
--------------------------------------------------------------------------------------------------------*/
.payment-options .MuiAccordionSummary-root {
  padding: 0;
  min-height: auto;
  border-radius: 8px;
}
.payment-options .MuiAccordionSummary-root.Mui-focusVisible {
  background: rgba(0, 0, 0, 0.06);
}
.payment-options .MuiAccordionSummary-root .MuiAccordionSummary-content {
  margin: 0;
  position: relative;
}
.payment-options .MuiAccordionSummary-root label {
  display: flex;
  position: relative;
  padding: 18px 14px 18px 42px;
  min-height: 60px;
  font-size: 14px;
  line-height: 18px;
  color: #5f5f5f;
  font-weight: 500;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.payment-options .MuiAccordionSummary-root input[type='radio'] {
  position: absolute;
  z-index: 2;
  margin: 0;
  opacity: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  border-radius: 7px;
}
.payment-options .MuiAccordion-root {
  border: 1px solid #d8d8d8;
  border-radius: 8px !important;
  box-shadow: none;
}
.payment-options .MuiAccordion-root:not(:last-child) {
  margin-bottom: 10px;
}
.payment-options .MuiAccordion-root::before {
  display: none;
}
.payment-options .MuiAccordion-root .MuiAccordionDetails-root {
  padding: 0 16px 10px;
}
.payment-options .MuiAccordion-root.Mui-expanded {
  margin: 0 0 10px;
}
.payment-options .MuiAccordionSummary-root.Mui-expanded {
  min-height: auto;
}
.payment-options .MuiAccordionSummary-root label::after,
.payment-options .MuiAccordionSummary-root label::before {
  content: '';
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  transition: all 0.3s ease;
  border-radius: 50%;
  border: 1px solid #737373;
  background: #fff;
  top: 20px;
  left: 14px;
}
.payment-options .MuiAccordionSummary-root label::before {
  height: 14px;
  width: 14px;
  transform-origin: center;
  background: #3d8af7;
  z-index: 1;
  border: none;
  left: 17px;
  top: 23px;
  transform: scale(0.25);
  opacity: 0;
}
.payment-options
  .MuiAccordionSummary-root
  input[type='radio']:checked
  ~ label::before {
  transform: scale(1);
  opacity: 1;
}
.payment-options
  .MuiAccordionSummary-root
  input[type='radio']:checked
  ~ label::after {
  border-color: #3d8af7;
}

.payment-options {
  width: 100%;
}

/* primary-datepicker
--------------------------------------------------------------------------------------------------------*/
.primary-datepicker .MuiInput-root {
  background-image: url('./components/Designer/assets/images/ic-calendar.svg');
  background-repeat: no-repeat;
  background-size: 18px 15px;
  background-position: center right;
}
.datepicker-dialog .PrivatePickersToolbar-root {
  padding: 16px 24px;
}
.datepicker-dialog .MuiButton-root {
  color: #3d8af7;
}
.datepicker-dialog .MuiButtonBase-root.Mui-selected,
.datepicker-dialog .MuiButtonBase-root:focus.Mui-selected {
  background: #3d8af7;
}
.datepicker-dialog .MuiPickersDay-root:not(.Mui-selected) {
  border-color: #3d8af7;
}
.datepicker-dialog .MuiSvgIcon-root {
  fill: #737373;
}
.datepicker-dialog .Mui-selected {
  background: #3d8af7 !important;
}
.datepicker-dialog .MuiInputBase-root:after {
  border-bottom: 1px solid #3d8af7;
}
.datepicker-dialog .MuiInputBase-root:before {
  border-bottom: 1px solid #d8d8d8 !important;
}
.datepicker-dialog .MuiInputLabel-root.Mui-focused {
  color: #3d8af7;
}
.datepicker-dialog .MuiInput-input {
  color: #5f5f5f;
}

/* payment-info
--------------------------------------------------------------------------------------------------------*/
.payment-info {
  display: flex;
  flex-direction: column;
  margin-bottom: 45px;
  align-items: center;
}
.payment-info > p {
  font-size: 14px;
  line-height: 19px;
  padding-bottom: 11px;
  font-weight: 500;
  color: #5f5f5f;
}
.payment-info > p strong {
  font-weight: 700;
  color: #3d8af7;
  text-align: center;
  display: block;
  font-size: 24px;
  line-height: 36px;
}
.payment-info > span {
  font-size: 12px;
  line-height: 20px;
  color: #737373;
  padding-bottom: 2px;
}
.payment-info > span strong {
  font-weight: 600;
}
.payment-info .btn-list {
  margin-top: 24px;
}

/* feedback-box
--------------------------------------------------------------------------------------------------------*/
.feedback-box {
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;
}
.feedback-box .MuiButton-root {
  min-width: 240px;
}
.feedback-box .top {
  background: #008cff;
  overflow: hidden;
  padding: 20px 10px 0;
}
.feedback-box .star-feedback {
  padding: 0px 0 34px;
  position: relative;
}

.star-feedback p {
  position: absolute;
  top: 46px;
  font-size: 14px;
  color: #d32f2f;
}
.feedback-box .top > div {
  margin-top: -9px !important;
  margin-bottom: -6px !important;
}
.feedback-box .MuiFormControlLabel-root .MuiTypography-root {
  font-size: 14px;
  line-height: 18px;
}
.feedback-box h5 {
  color: #fff;
  font-weight: 600;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
}
.feedback-box .card.bordered {
  border-radius: 0;
  padding-top: 24px;
  padding-bottom: 20px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin: 0;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.feedback-box .card.bordered h5 {
  color: #737373;
  padding-bottom: 16px;
  font-size: 16px;
  line-height: 24px;
}
.feedback-box .card.bordered .form-group {
  margin-bottom: 30px;
}
.feedback-box .card.bordered .form-group.feedback-input {
  margin-bottom: 24px;
}
.feedback-box .feedback-points {
  padding: 0 8px;
  margin-bottom: 30px;
}
.feedback-points {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -5px;
}
.feedback-points > li {
  padding: 0 5px;
  flex-grow: 0;
  min-width: 33.33%;
  flex-shrink: 1;
  position: relative;
  margin-bottom: 10px;
  flex-basis: 33.33%;
  max-width: 33.33%;
}
.feedback-points > li input[type='checkbox'] {
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  opacity: 0;
}
.feedback-points > li label {
  font-size: 11px;
  cursor: pointer;
  display: block;
  width: 100%;
  line-height: 16px;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  color: #737373;
  border: 1px solid #d8d8d8;
  border-radius: 50px;
  padding: 8px 9px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all 0.3s ease;
}
.feedback-points > li label span {
  position: relative;
  z-index: 1;
}
.feedback-points > li label::after {
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  border-radius: 50px;
  position: absolute;
  transition: all 0.3s ease;
  background: rgba(0, 140, 255, 0.05);
  transform: scale(0, 1);
}
.feedback-points > li input[type='checkbox']:checked ~ label {
  color: #3d8af7;
  border-color: #3d8af7;
}
.feedback-points > li input[type='checkbox']:checked ~ label::after {
  transform: scale(1, 1);
}

/* star-feedback
--------------------------------------------------------------------------------------------------------*/
.star-feedback {
  display: flex;
  justify-content: center;
}
.star-feedback button {
  background: transparent;
  width: 33px;
  height: 31px;
  margin: 0 10px;
  border: none;
  position: relative;
}
.star-feedback button img {
  width: 33px;
  height: 31px;
  cursor: pointer;
  display: block;
  transition: all 0.3s ease;
  position: absolute;
  top: 0;
  left: 0;
}
.star-feedback button img.active {
  transform: scale(0.4);
  opacity: 0;
}
.star-feedback button.active img.active {
  transform: scale(1);
  opacity: 1;
}
.star-feedback button.active img.default {
  opacity: 0;
}

/* final-message
--------------------------------------------------------------------------------------------------------*/
.final-message {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.final-message h2 {
  color: #737373;
  font-weight: 600;
  text-align: center;
}
.payment-success {
  color: #737373;
  font-weight: 600;
  text-align: center;
}
.final-message h6 {
  color: #5f5f5f;
  text-align: center;
  max-width: 343px;
}
.final-message h6 strong {
  font-weight: 700;
  display: block;
  text-align: center;
}
.email-sent {
  padding-top: 30px;
}
.email-sent .animation-wrapper {
  margin-bottom: 50px;
}
.email-sent h6 {
  padding-bottom: 46px;
}
.thanks-message {
  padding-top: 20px;
}
.thanks-message .animation-wrapper {
  margin-bottom: 10px;
}
.thanks-message h6 {
  padding-bottom: 36px;
}
.account-created {
  padding-top: 30px;
  position: relative;
  z-index: 1;
}
.account-created h2 {
  margin-top: -17px;
  position: relative;
  z-index: 1;
}
.account-created h6 {
  margin-bottom: 42px;
}
.account-created .animation-wrapper {
  height: 300px;
  width: 300px;
}
.account-created .animation-wrapper > img {
  display: none;
}
.account-created .animation-wrapper > img.show {
  display: block;
  animation-name: show;
  animation-duration: 1s;
  animation-timing-function: ease;
}
.account-created-successful-animation {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* social-media-login
--------------------------------------------------------------------------------------------------------*/
.social-media-login {
  padding-bottom: 20px;
}
.social-media-login .MuiButton-root {
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 21px;
  width: 100%;
  font-weight: 400;
}
.social-media-login .MuiButton-root img {
  height: 26px;
  width: 26px;
  margin-right: 10px;
}
.termsClass {
  font-size: 14px !important;
  text-align: center !important;
}
.login-box .MuiButton-root {
  /* margin-top: 15px; */
  width: 100%;
}
.login-box .social-media-login .MuiButton-root {
  margin-top: 0;
}
.login-box h6 {
  color: #616161;
  line-height: 21px;
  text-align: center;
  padding-bottom: 15px;
}
.login-box .separator-text {
  padding: 20px 0;
  text-transform: uppercase;
  line-height: 21px;
  color: #a6a6a7;
  text-align: center;
}

.login-box .separator-text-normal {
  padding: 20px 0;
  line-height: 21px;
  color: #a6a6a7;
  text-align: center;
}
.login-box h6 a {
  padding-left: 4px;
}
.login-box a {
  font-size: 14px;
  line-height: 21px;
}
.login-box .forgot-password-link {
  margin-top: -5px;
  text-align: right;
  display: block;
  font-weight: 400;
}

/* forgot-password
--------------------------------------------------------------------------------------------------------*/
.card.bordered.forgot-password,
.otp-verification.card.bordered {
  margin-top: 34px;
  min-height: 585px;
}
.forgot-password .animation-wrapper > div,
.otp-verification .animation-wrapper > div {
  background: transparent;
}
.forgot-password .animation-wrapper {
  margin-bottom: 25px;
}
.forgot-password h4,
.otp-verification h4,
.create-password h4 {
  font-weight: 600;
  color: #737373;
  text-align: center;
  padding-bottom: 15px;
}
.otp-verification h3 {
  font-weight: 600;
  color: #737373;
  text-align: center;
  padding-bottom: 6px;
  margin-bottom: 25px;
}
.forgot-password h6,
.otp-verification h6,
.create-password h6 {
  max-width: 307px;
  text-align: center;
  margin: 0 auto;
  color: #737373;
  padding-bottom: 50px;
}
.forgot-password .MuiButton-root {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
}
.forgot-password a {
  font-size: 14px;
  text-align: center;
  display: block;
}

/* otp-verification
--------------------------------------------------------------------------------------------------------*/
.otp-verification {
  padding-top: 36px;
}
.otp-verification .animation-wrapper {
  margin-bottom: 34px;
}
.otp-verification .otp-input-wrapper {
  margin-bottom: 30px;
}
.otp-verification h6 {
  padding-bottom: 32px;
}
.otp-input-wrapper .otp-input input {
  height: 50px;
  transition: all 0.3s ease;
  font-size: 24px;
  line-height: 36px;
  color: #212121;
  font-weight: 600;
  width: 50px !important;
  border: none;
  border-bottom: 2px solid #d8d8d8;
}
.otp-input-wrapper .otp-input input:focus {
  border-bottom-color: #008cff;
}
.otp-input-wrapper .otp-input {
  margin: 0 5px;
}
.otp-input-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.otp-verification .MuiButton-root {
  margin: auto;
  margin-bottom: 30px;
}
.enable-two-fa {
  color: #616161;
}
.otp-verification p {
  font-size: 14px;
  line-height: 19px;
  color: #737373;
  text-align: center;
}
.otp-verification p a {
  padding-left: 4px;
}

/* create-password
--------------------------------------------------------------------------------------------------------*/
.create-password {
  padding-top: 24px;
  margin-top: 30px;
  min-height: 350px;
}
.create-password h6 {
  padding-bottom: 40px;
}
.create-password .MuiButton-root {
  margin: 30px 0 20px;
  width: 100%;
}

/* password-changed
--------------------------------------------------------------------------------------------------------*/
.password-changed .animation-wrapper {
  margin-top: -20px;
  margin-bottom: 20px;
}
.password-changed h6 {
  padding-bottom: 36px;
}
.my-profile.card.bordered {
  margin: 0;
}
.my-profile {
  min-height: calc(100vh - 147px);
}
.my-profile .MuiButton-root {
  margin-top: 30px;
  width: 100%;
}

/* primary-dialog
--------------------------------------------------------------------------------------------------------*/
.primary-dialog.faq-dialog .MuiDialog-paper {
  height: 100% !important;
}
.primary-dialog .MuiDialog-paper {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  width: calc(100% - 34px);
  margin: 32px 17px;
  border-radius: 15px;
}
.primary-dialog .MuiDialogTitle-root {
  display: flex;
  padding: 14px 15px 14px 20px;
  justify-content: space-between;
}
.primary-dialog .MuiDialogTitle-root .MuiButton-root img {
  width: 16px;
  margin: 0;
  position: relative;
  z-index: 1;
  height: 18px;
}
.primary-dialog .MuiDialogTitle-root .MuiButton-root:hover,
.primary-dialog .MuiDialogTitle-root .MuiButton-root:focus {
  background: #efefef;
}
.primary-dialog .MuiDialogTitle-root .MuiButton-root {
  height: 30px;
  width: 30px;
  padding: 0;
  min-height: auto;
  min-width: auto;
}
.primary-dialog .MuiDialogTitle-root h5 {
  text-transform: uppercase;
  padding-top: 6px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #737373;
}
.primary-dialog .MuiDialogContent-root {
  padding-left: 18px;
  padding-right: 18px;
}
/* split-options
--------------------------------------------------------------------------------------------------------*/
.split-options {
  padding: 20px 0 6px;
}
.split-options .MuiButton-root {
  border-radius: 6px;
  width: 100%;
  min-height: 94px;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 0 20px;
  font-size: 20px;
  font-weight: 400;
  text-align: start;
  border-color: #cee9ff;
}
.split-options .MuiButton-root img {
  display: block;
  height: 70px;
  width: 70px;
}
.split-options .MuiButton-root .icon-wrapper {
  display: flex;
  position: relative;
  z-index: 1;
  align-items: center;
  width: 140px;
  margin-left: auto;
  justify-content: flex-end;
  padding: 12px 20px;
}
.split-options .MuiButton-root:not(:last-child) {
  margin-bottom: 20px;
}
.split-options .MuiButton-outlinedPrimary.MuiButton-root::after {
  display: none;
}
.split-options .MuiButton-root::before {
  content: '';
  position: absolute;
  top: 0;
  right: -20px;
  bottom: 0;
  width: 140px;
  transition: all 0.3s ease;
  background: #cee9ff;
  transform: skew(-23deg, 0);
}
.split-options .MuiButton-root:hover::before,
.split-options .MuiButton-root:focus::before {
  width: 100%;
  right: 0;
  transform: skew(0, 0);
}
.split-options .MuiButton-outlinedPrimary.MuiButton-root:hover,
.split-options .MuiButton-outlinedPrimary.MuiButton-root:focus {
  border-color: #cee9ff;
  background: none;
}
.main-menu .MuiButton-root.MuiButton-containedSecondary {
  width: 100%;
}
.main-menu
  .MuiButton-root.MuiButton-containedSecondary
  span:not(.MuiTouchRipple-root) {
  width: 102px;
  text-align: center;
}
/* edit-split
--------------------------------------------------------------------------------------------------------*/
.edit-split {
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.edit-split .page-title {
  padding-bottom: 0;
}
.edit-split > span {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  line-height: 19px;
  color: #737373;
  padding-bottom: 12px;
}
.edit-split span strong {
  font-weight: 600;
}
.edit-split a {
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  line-height: 18px;
  font-weight: 400;
  margin-bottom: -3px;
}
.edit-split a img {
  height: 12px;
  width: 12px;
  margin-right: 8px;
}
.user-name {
  text-align: center;
  font-size: 16px;
  padding: 10px 20px 10px;
  line-height: 26px;
  font-weight: 500;
  color: #008cff;
}

/* split-by-guest
--------------------------------------------------------------------------------------------------------*/
.split-by-guest-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.split-guest-dialog-circle {
  width: 40%;
  height: 15vh;
  border: 2px solid #3d8af7;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  color: #000;
  margin: 20px 0px 20px 0px;
}
.split-by-guest {
  display: flex;
  flex-direction: column;
}

.counter {
  justify-content: center;
  margin-top: 10px;
  display: flex;
}
.counter button {
  transition: all 0.3s ease;
  background: #fff;
  cursor: pointer;
}
.counter button:hover {
  background: #f5f5f5;
  border-radius: 50px;
}
.counter button:first-child,
.counter button:last-child {
  border: none;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.counter button img {
  display: block;
  height: 18px;
  width: 18px;
}

.counter span {
  border: 1px solid #d8d8d8;
  border-radius: 25px;
  overflow: hidden;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: 20px;
  line-height: 30px;
  color: #737373;
  font-weight: 600;
  min-width: 60px;
  padding: 20px 40px;
  text-align: center;
  margin: 0px 10px;
}
.split-by-guest .separator {
  height: 1px;
  background-image: url('./components/Designer/assets/images/ic-dash-border.svg');
  background-repeat: repeat;
  margin: 12px 0;
}
.split-by-guest ul {
  padding-bottom: 10px;
}
.split-by-guest ul li {
  display: flex;
  margin-bottom: 8px;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  line-height: 24px;
  color: #737373;
}
.split-by-guest ul li span,
.split-by-guest ul li strong {
  display: block;
  letter-spacing: 0.5px;
}
.split-by-guest ul li strong {
  font-weight: 600;
}
.split-by-guest .MuiButton-contained {
  margin: 0 auto 6px;
}
.apexcharts-text {
  filter: none !important;
}
/* loader
--------------------------------------------------------------------------------------------------------*/
.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.6);
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: 99999;
  display: flex;
  transition: all 0.3s ease;
  align-items: center;
  justify-content: center;
}

.loader-wrapper p {
  position: absolute;
  margin-bottom: 128px;
  /* top: 80px; */
  z-index: 100000;
  width: 100%;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #008cff;
}

body.show-loader {
  overflow: hidden;
}
.show-loader .loader-wrapper {
  visibility: visible;
  opacity: 1;
}
.loader {
  width: 80px;
  height: 80px;
  font-size: 80px;
  position: relative;
  /* display: inline-block; */
}
.loader:before,
.loader:after {
  content: '';
  top: 0;
  display: block;
  width: 1em;
  height: 1em;
  position: absolute;
  border-width: 0.5em;
  border-style: double;
  border-color: transparent;
  box-sizing: border-box;
  border-radius: 1em;
  -webkit-animation: loader 2s infinite;
  animation: loader 2s infinite;
}
.loader:after {
  left: 0;
  border-left-color: #008cff;
}
.loader:before {
  right: 0;
  border-right-color: #008cff;
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
@-webkit-keyframes loader {
  from {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/* Alert-dialog
--------------------------------------------------------------------------------------------------------*/
.primary-dialog.alert-dialog .MuiDialogContent-root > img {
  height: 60px;
  display: block;
  width: 60px;
}
.primary-dialog.alert-dialog .MuiDialogContent-root {
  min-height: 104px;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  justify-content: center;
  flex-direction: column;
  padding-top: 0 !important;
}
.primary-dialog.alert-dialog.d-block .MuiDialogContent-root {
  display: block !important;
}
.primary-dialog.alert-dialog .MuiDialogTitle-root .MuiButton-root {
  margin-left: auto;
}
.primary-dialog.alert-dialog .btn-list .MuiButton-root {
  min-width: 140px;
}
.primary-dialog.alert-dialog .btn-list {
  justify-content: center;
  margin: 0 auto 10px;
}
.primary-dialog.alert-dialog .MuiDialogContent-root p {
  text-align: center;
  font-weight: 500;
  font-size: 18px;
}

.empty-tab-image {
  max-width: 194px;
  margin: 20px auto 0;
  display: block;
}

.item-count-span {
  font-size: 12px;
}

/* footer
--------------------------------------------------------------------------------------------------------*/
.footer.hide-footer {
  visibility: hidden;
  opacity: 0;
}
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}
.footer::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(8px);
}
.browser-firefox .footer::after {
  background: rgba(255, 255, 255, 0.92);
}
.footer .MuiContainer-root {
  display: flex;
  position: relative;
  z-index: 110;
  flex-direction: column;
  padding: 10px 19px 10px;
  align-items: center;
  justify-content: space-between;
}
.footer p {
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: #737373;
  font-weight: 500;
  padding-bottom: 5px;
  width: 100%;
}
.footer .payment-not-available {
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #737373;
  font-weight: 500;
  padding-bottom: 5px;
  width: 100%;
}
.payment-popup-logo {
  height: 30px !important;
}

.footer strong {
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  color: #737373;
}
.footer .MuiButton-root {
  font-weight: 400;
  width: 100%;
}
.footer .MuiButton-root strong {
  font-weight: 600;
  display: inline;
  color: #fff;
}
.table-details-body > ul li.remaining-amount > span,
.table-details-body > ul li.remaining-amount > p {
  font-size: 14px;
  line-height: 18px;
  color: #333;
  font-weight: 500;
}

/* Responsive Media Queries
--------------------------------------------------------------------------------------------------------*/
@media (min-width: 414px) {
  .MuiButtonBase-root.MuiButton-root {
    min-width: 240px;
  }
  .datepicker-dialog .MuiButton-root {
    min-width: auto;
  }
  .feedback-points > li {
    flex-basis: 0;
    max-width: unset;
  }
}
@media (min-width: 576px) {
  .primary-dialog.alert-dialog .btn-list .MuiButton-root {
    min-width: 180px;
  }
  .tip-radios li.custom-tip .custom-percent {
    top: 9px;
  }
  .otp-input-wrapper .otp-input {
    margin: 0 10px;
  }
  .footer.hide-footer {
    visibility: visible;
    opacity: 1;
  }
  .header.MuiContainer-root {
    padding: 15px 20px 12px !important;
  }
  .header .resturant-info p {
    font-size: 18px;
    padding-bottom: 4px;
  }
  .wrapper {
    padding-top: 96px;
  }
  .MuiButtonGroup-root.main-tab .MuiButton-root {
    min-width: 210px;
    font-size: 14px;
    line-height: 18px;
    padding: 13px 6px;
  }
  .MuiButtonGroup-root.main-tab {
    width: 420px;
    margin-bottom: 26px;
  }
  .MuiButtonGroup-root.main-tab .MuiButton-root img {
    margin-right: 8px;
  }
  .secondary-tabs.MuiToggleButtonGroup-root {
    padding: 20px 0;
  }
  .page-title {
    padding-bottom: 20px;
    font-size: 20px;
  }
  .table-details-collapse .MuiAccordionSummary-root {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .table-details-body > ul li {
    padding-top: 8px;
    padding-bottom: 7px;
  }
  .table-details-body > ul li > span {
    font-size: 16px;
    line-height: 20px;
    color: #737373;
  }
  .table-details-body > ul li.grand-total p {
    font-size: 20px;
    line-height: 24px;
  }
  .table-details-body > ul li small {
    font-size: 12px;
  }
  .table-details-collapse .MuiAccordionDetails-root ul li span {
    font-size: 14px;
  }
  .tip-radios li label strong {
    padding-top: 2px;
  }

  .tip-radios li.custom-tip {
    flex-basis: 0;
    margin-top: 0;
  }
  .tip-radios li.custom-tip .form-group .MuiFormControl-root .MuiInput-root {
    margin-top: 10px;
  }
  .tip-radios li.custom-tip .form-group {
    padding: 14px 10px 4px;
  }
  .tip-radios li > label span {
    font-size: 16px;
  }
  .tip-radios {
    flex-wrap: nowrap;
  }
  .feedback-points > li label {
    font-size: 14px;
    line-height: 20px;
    min-height: 40px;
  }
  .MuiGrid-root.MuiGrid-spacing-xs-3 > .MuiGrid-item {
    padding-left: 18px !important;
    padding-top: 18px !important;
  }
  .MuiGrid-root.MuiGrid-spacing-xs-3 {
    margin-left: -18px !important;
    margin-top: -18px !important;
    width: calc(100% + 18px) !important;
  }
  .my-profile {
    min-height: calc(100vh - 173px);
  }
  .sm-card {
    max-width: 400px;
    margin: 0 auto;
    padding: 16px 0;
  }
  .footer .MuiButton-root {
    width: auto;
    min-width: 300px;
  }
  .main-menu .MuiButton-root.MuiButton-containedSecondary {
    max-width: 400px;
    margin: 0 auto;
  }
}

.checkbox-remove-center {
  padding-top: 0px !important;
}
label:has(> .checkbox-remove-center) {
  -webkit-align-items: flex-start !important;
  align-items: flex-start !important;
}

/* receipts-wrapper*/
.receipt {
  display: flex;
  padding: 15px;
  align-items: center;
}

.receipt > img {
  height: 48px;
  width: 48px;
  display: block;
  margin-right: 14px;
}

.receipt .details {
  flex-grow: 1;
  max-width: calc(100% - 62px);
}

.receipt .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
}

.receipt .title h6 {
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  color: #5f5f5f;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 14px;
}

.receipt .title p {
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  flex-shrink: 0;
  color: #212121;
}

.receipt .sub-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.receipt .sub-details > span {
  font-size: 12px;
  line-height: 18px;
  color: #737373;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 14px;
}

.receipt .sub-details a {
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 2px;
  white-space: nowrap;
}

.receipt .sub-details a img {
  display: block;
  height: 20px;
  width: 20px;
  transition: all 0.3s ease;
}

.receipt .sub-details a:hover img {
  transform: translateX(4px);
}

.receipt .title h6 {
  font-size: 16px;
}

/*Page Top for guest receipt*/
.page-top {
  padding: 24px 0 14px;
  display: flex;
  align-items: center;
  min-height: 76px;
}

.page-top .right {
  display: flex;
  margin-left: auto;
}

.dateFilterInput {
  border-radius: 4px;
  padding: 13px 0 12px 10px;
  box-sizing: content-box;
  border-color: #d8d8d8;
  border-width: 1px;
  appearance: none;
  border-style: solid;
  line-height: 22px;
  color: #737373;
  font-size: 15px;
  box-sizing: border-box;
  width: 100%;
  font: inherit;
}

.dateFilter {
  display: flex;
  background: #fff;
}

.dateFilter ul {
  border-right: 2px solid rgba(0, 0, 0, 0.12);
}

.dateFilter li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 13px;
}

.dateFilter li:hover {
  background: rgba(0, 0, 0, 0.04);
}

.dateFilter li button {
  display: block;
  width: 100%;
  text-align: left;
  font-size: 12px;
  background: none;
  color: inherit;
  border: none;
  padding: 8px 12px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.no-data-found {
  text-align: center;
  margin-top: 10px;
  font-size: 30px;
  color: #737373;
}
.pagination-wrapper .MuiPaginationItem-root.Mui-selected {
  background: #008cff;
  color: #fff;
}

.apply-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
}
.apply-promo-btn {
  margin-bottom: 0px !important;
  margin-left: 15px !important;
}

.subscription-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px !important;
  width: 70%;
  margin: auto;
  margin-block: 60px;
}
.subscription-form {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 10px;
  padding-top: 90px;
  padding-bottom: 20px;
}
.subscription-form-width {
  width: 70%;
}

.subscription-pay-now {
  width: 100% !important;
  color: white;
  border-radius: 10px;
  padding: 12px;
  font-size: 18px;
  border: none;
  font-weight: 500;
}

.subscription-fastab-logo {
  height: 90px;
  margin-bottom: 50px;
}

.subscription-desc-text {
  /* width: 60%; */
  margin: 10px;
  color: rgb(115, 115, 115);
  text-align: center;
}

.subscription-start-from {
  background-color: #00a5ff;
  width: 35%;
  border-radius: 10px;
  margin: 0px auto;
}

.input-box-border {
  border: 1px;
  border-radius: 4px;
  padding: 8px;
}

@media (max-width: 512px) {
  .apply-promo-btn {
    margin-bottom: 0px !important;
    margin-left: -5px !important;
  }

  .subscription-desc-text {
    width: 100%;
    margin: 10px auto;
    color: rgb(115, 115, 115);
    text-align: center;
  }

  .subscription-start-from {
    background-color: #00a5ff;
    width: 70%;
    border-radius: 10px;
    margin: 0px auto;
  }
}
@media (max-width: 600px) {
  .subscription-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px !important;
    width: 80%;
    margin: auto;
    margin-block: 60px;
  }
  .subscription-form-width {
    width: 80%;
  }
}

.remove-card-padding {
  padding-left: 12px;
  padding-right: 12px;
  padding: 25px;
}

.subscription-inner-card {
  display: inline-grid;
  background-color: #008cff;
  width: 100%;
  color: white;
  text-align: center;
  padding: 30px;
}

.subscription-inner-card span {
  padding: 3px;
}

.no-padding {
  padding: 0px !important;
}

.font-16 {
  font-size: 16px;
}
.font-24 {
  font-size: 26px;
}
.font-bold-600 {
  font-weight: 600;
}
.font-bold {
  font-weight: 500;
}
.sublist-subscription-card {
  list-style-type: disc;
  margin-left: 30px;
}
.subscription-success-logo {
  height: 120px;
  margin-bottom: 50px;
}

/*Redeem Page Guest marketing*/

.redeem-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.redeem-header {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  text-align: center;
  margin: 0px 14px 30px 14px;
}
.coupon-code-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.coupon-code-container input {
  width: 100%;
  padding: 10px;
  font-size: 20px;
  font-weight: bold;

  text-align: center;
  border: 2px solid #ddd;
  border-radius: 5px 0 0 5px;
  outline: none;
}

/*Redeem Page Guest marketing*/
